import { Component } from '@angular/core';

@Component({
  selector: 'app-le-restaurant',
  templateUrl: './le-restaurant.component.html',
  styleUrls: ['./le-restaurant.component.css']
})
export class LeRestaurantComponent {

}
