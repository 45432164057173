<h1>La Gourmande</h1>
<div class="le-restaurant">
  <div class="description">
    <h2>Le restaurant</h2>
    <p>
      <span>
        Le restaurant-bar La Gourmande vous accueille du lundi au samedi dans une ambiance conviviale et chaleureuse.
      </span>
      <span>
        Le restaurant est composé de 2 salles avec 2 ambiances (l'une brasserie, l'autre restaurant traditionnel) selon votre convenance.
      </span>
    </p>
    <div>
      <img src="assets/Salle.jpg" alt="salle" width="400px" />
    </div>
    <h2>La carte</h2>
    <p>
      <span>
        La carte permet de satisfaire toutes vos envies et attentes.
      </span>
      <span>
        Mon choix et de proposer des plats à base de produits et matières premières du jour, s'adaptant à tout budget.
      </span>
      <span>
        Pour cela, j'ai décidé d'adapter ma carte selon les saisons et les arrivages :
        <ul>
          <li>La salade du jour</li>
          <li>Le plat du jour</li>
          <li>Le burger du jour</li>
          <li>Le poisson du jour</li>
          <li>La viande du jour</li>
          <li>Le dessert du jour</li>
          <li>La gourmandise : le dessert de la semaine</li>
        </ul>
      </span>
      <span>
        Côte boisson, une carte de vin est accessible pour tous les budgets avec 3 bières et un cidre, tous à la pression, sont également proposés.
      </span>
    </p>
    <p>
      <span>
        Toute l'équipe d'Isabelle a pour objectif de rendre votre moment agréable et bon.
      </span>
    </p>
    <div>
      <div>
        <img src="assets/Plat_2.jpg" alt="plat_2" height="200px" />
      </div>
    </div>
  </div>
  <div class="coordonnees">
    <span class="titre">Restaurant La Gourmande</span>
    <span>1 rue Saint Sauveur</span>
    <span>80200 Péronne</span>
    <span class="telephone pi pi-phone">&nbsp;+33 3 22 25 58 24</span>
    <div class="horaires">
      Ouvert du lundi au samedi.
      <br />
      Fermé lundi soir et jeudi soir.
    </div>
  </div>
</div>
