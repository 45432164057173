import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterTestingModule } from "@angular/router/testing";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { LeRestaurantComponent } from './components/le-restaurant/le-restaurant.component';
import { MenuPrincipalComponent } from './components/menu-principal/menu-principal.component';
import { primengModules } from './app.primeng-config';
import { CarteComponent } from './components/carte/carte.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './components/app-routing.module';

@NgModule({
  declarations: [
    AppComponent,
    LeRestaurantComponent,
    MenuPrincipalComponent,
    CarteComponent
  ],
  imports: [
    BrowserModule,
    RouterTestingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    primengModules
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
