import { Component } from '@angular/core';
import { CalendarModule } from 'primeng/calendar';

import { TabMenuModule } from 'primeng/tabmenu';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-menu-principal',
  templateUrl: './menu-principal.component.html',
  styleUrls: ['./menu-principal.component.css']
})
export class MenuPrincipalComponent {

  items!: MenuItem[];
  active!: MenuItem;

  ngOnInit() {
    this.items = [
      { label: 'Le restaurant', icon: 'pi pi-fw pi-box', routerLink: 'le-restaurant' },
      { label: "La carte", icon: 'pi pi-fw pi-map', routerLink: 'carte' }
    ];

    this.active = this.items[1];
  }
}
