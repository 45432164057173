import {TabMenuModule} from 'primeng/tabmenu';
import {MenuItem} from 'primeng/api';
import {ButtonModule} from 'primeng/button';
import {CardModule} from 'primeng/card';

export const primengModules = [
  TabMenuModule,
  ButtonModule,
  CardModule
];
