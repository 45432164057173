<div class="header">
  <!-- <img src="assets/favicon.jpg" alt="La Gourmande" width="50px" /> -->
  <div class="logo description">Restaurant Bar</div>
  <div class="logo titre">La Gourmande</div>
  <div class="logo description">Bar Restaurant</div>
</div>

<app-menu-principal></app-menu-principal>

<div class="contenu">
  <router-outlet></router-outlet>
</div>
