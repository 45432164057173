import { Component, ViewEncapsulation } from '@angular/core';
import {Carousel, CarouselModule} from 'primeng/carousel';


@Component({
  selector: 'app-carte',
  templateUrl: './carte.component.html',
  styleUrls: ['./carte.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CarteComponent {
  pdfSrc: string = "";
  display: boolean = false;
  images!: string[];
  responsiveOptions!: Carousel["responsiveOptions"];

  ngOnInit() {
  }

  showDialog(source: string) {
    this.display = true;
    this.pdfSrc = source;
  }
}
