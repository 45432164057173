<div class="la-carte">
  <div>
    <p-card>
      <a href="assets/Carte.pdf" target="_blank" class="contenu-card"
        download="Carte.pdf">
        <h1>La carte</h1>
        <p>Voir la carte</p>
      </a>
    </p-card>
  </div>
</div>

<div class="la-carte plats">

  <div>
    <img src="assets/Plat_1.jpg" alt="plat_1" width="350px" />
  </div>

  <div>
    <img src="assets/Plat_3.jpg" alt="plat_3" width="350px" />
  </div>

  <div>
    <img src="assets/Plat_5.jpg" alt="plat_5" width="350px" />
  </div>

</div>
