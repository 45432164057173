import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CarteComponent } from './carte/carte.component';
import { LeRestaurantComponent } from './le-restaurant/le-restaurant.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'le-restaurant',
    pathMatch: 'full',
  },

  {
    path: 'le-restaurant',
    pathMatch: 'full',
    component: LeRestaurantComponent,
  },

  {
    path: 'carte',
    component: CarteComponent,
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
